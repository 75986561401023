
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import ResultsFamily from '@/components/ResultsFamily.vue';
import QualificationsComp from '@/components/QualificationsComp.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Qualifications',
  components: {
    Header,
    ResultsFamily,
    QualificationsComp,
    Footer,
  },
});
