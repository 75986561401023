
import { defineComponent } from 'vue';
import Form from './Form.vue';

export default defineComponent({
  name: 'QualificationsComp',
  components: {
    Form,
  },
});
